<template>
  <main :class="{ 'padding-webview': checkWebview(), 'padding-has-caution': isHasCaution }">
    <div class="exchange-service-common">
      <!-- TITLE SERVICE -->
      <section class="intro-service container">
        <!-- INFO SERVICE -->
        <h3 class="sp title-name">デジタルKFC CARD</h3>
        <div class="title-left">
          <img src="../../../assets/images/kfc.png" alt="logo">
          <div class="info">
            <h3 class="pc title-name">デジタルKFC CARD</h3>
            <p>ケンタッキーフライドチキン専用のデジタル版プリペイドカード。<br>
              いつものケンタッキーを、キャッシュレスで、よりスマートにご利用頂けます。<br>
              全国のケンタッキーフライドチキン各店でご利用いただけます。（一部特殊店舗除く）<br>
              土日祝日を含む朝食・ランチ・ディナー全日ご利用いただけます。<br>
              各店舗の営業時間、休業日については各店舗までお問合せください。
            </p>
          </div>
        </div>

        <!-- RULES SERVICE -->
        <ul class="rules-service">
          <li>
            <span>交換日数</span>
            <p><b>{{ pointExchangeService.exchange_date }}</b></p>
          </li>
          <li>
            <span>交換手数料</span>
            <p><b>無料</b></p>
          </li>
          <li>
            <span>交換レート</span>
            <strong class="unit-gpoint"><div>1pt →</div><span>1円相当</span></strong>
          </li>
          <li>
            <span>最低交換ポイント</span>
            <p class="point">500pt</p>
          </li>
        </ul>
      </section>

      <!-- NOTE SERVICE -->
      <section class="note-service d-flex">
        <div class="container d-inline-block">
          <p>【利用方法】<br>
            画面を明るくし、「QRコードを表示」ボタンを押して、店頭でデジタルKFCカードでのお支払いをお伝えください。<br>
            ※スマートフォン以外の端末(PC、タブレット、フィーチャーフォン)ではご利用いただけません。 <br>
            ※「KFCカード取扱店」の掲示がある店舗でのみ、ご利用頂けます。<br>
            ※「球場、スタジアム、臨時販売店」等の一部店舗ではご利用頂けません。<br><br>
            ※KFCネットオーダーのお支払いには、ご利用頂けません。<br><br>
            【注意事項】<br>
            この商品の有効期限は2年間です。<br>
            デジタルKFC CARDは購入日より2年後（730日後）にチケット画面から遷移が不可になります。<br>
            残高が残っている場合は、受け取りボタン押下後に遷移するURLを別場所へ保管してください。<br>
            本商品は電子ギフトとなる為、画像内のカードがお手元に届くことはございません。<br>
            有効期限は、カードの発行日、最後にチャージした日、最後にバリューを使用した日、または、バリュー残高移行をした日のいずれか遅い日（当日を含む）から起算して2年間(1年365日)です。<br>
            有効期限経過後は無効となり、本カードは利用できません。払戻しもしませんので、ご注意ください。<br>
            盗難・URLの紛失による払戻しや再発行はしません。<br>
            カード番号およびPIN番号は、残高照会サイトへのログイン、バリュー残高移行に使用するものです。<br>
            残高照会サイトへのログイン、バリュー残高移行の際に、カード番号およびPIN番号の入力を当社所定の上限回数以上間違えた場合、本カードが利用できなくなります。<br>
            第三者により本カード、カード番号および認証番号が使用された場合、本人による利用とみなし、当社は責任を負いません。管理には十分ご注意ください。<br>
            本カード1枚あたりのチャージできる上限額は50,000円です。1回あたりの上限額は49,500円となります。<br>
            システム障害等により予告なく一時的に本カードが利用できない場合があります。<br>
            本カードは、換金することができません。<br>
          </p>
        </div>
      </section>

      <!-- EXCHANGE POINT -->
      <div class="control-exchange">
        <!-- BTN EXCHANGE POINT -->
        <button @click="handleBeforeExchangePoint"
                v-if="profile && token"
                class="btn-exchange-point">
          <img src="../../../assets/images/icon/icon-coins.svg" alt="icon">ポイントを交換する
        </button>

        <!-- BTN redirect to login -->
        <button v-else @click="openPageNative('Login')"
                class="btn-exchange-point btn-redirect">
          すでに会員の方はこちらからログイン
        </button>

        <!-- BTN BACK -->
        <div class="link-back">
          <router-link class="btn-back"
                       to="/exchange-point">
            <span>
               <ChevronLeftIcon size="20" />ポイント交換先一覧へ戻る
            </span>
          </router-link>
        </div>
      </div>
    </div>

    <!-- MODAL INPUT EXCHANGE POINT -->
    <Modal @close-modal="flagModalInput = false"
           class="modal-center modal-exchange-point"
           v-if="flagModalInput">
      <ValidationObserver
        tag="form"
        ref="observer"
        @submit.prevent="validateBeforeSubmit()"
        slot="body"
        class="content-body dot-money">
        <!-- TITLE MODAL -->
        <h3>ポイント交換先<br class="sp">&#12288;デジタルKFC CARD </h3>
        <p class="sub-title">デジタルKFC CARDへの交換となります。</p>

        <!-- CONTENT -->
        <div class="point-exchange">
          <span>交換</span>
          <InputField v-model="pointExchange"
                      field="交換ポイント"
                      type="text"
                      class="point-type"
                      :max-length="15"
                      @input="handleNumber($event)"
                      @focusout="handleNumberFocusOut()"
                      @focusin="handleNumberFocusIn($event)"
                      :rules="{
                        required: true,
                        number_exchange: true,
                        compare_point: {
                          first: pointExchange && parseInt(pointExchange.replace(/,/g, '')),
                          second: user_point.total_point_amount
                        },
                        range_point: true
                      }"
                      vid="point"/>
          <span class="pc">pt</span>
        </div>

        <!-- IMG ICON ARROW DOWN -->
        <img class="pc" src="../../../assets/images/icon/arrow-down.svg" alt="icon">
        <img class="sp" src="../../../assets/images/icon/arrow-down-sp.svg" alt="icon">

        <div class="point-exchange point-line">
          <span>付与</span>
          <InputField v-model="pointExchange"
                      class="point-type"
                      :disabled="true"/>
          <span class="pc">円相当</span>
        </div>

        <!-- NOTE -->
        <p class="note">交換するポイントを入力して交換ポイントを確定するボタンをクリックしてください。</p>
      </ValidationObserver>

      <div class="btn-footer"
           slot="footer">
        <button @click.prevent="validateBeforeSubmit">
          <img src="../../../assets/images/icon/icon-coins.svg" alt="icon">交換ポイントを確定する
        </button>
      </div>
    </Modal>

    <!-- MODAL CONFIRM EXCHANGE POINT -->
    <Modal @close-modal="flagModalConfirm = false"
           class="modal-center modal-exchange-point"
           v-if="flagModalConfirm">
      <div slot="body"
           class="content-body dot-money">
        <!-- TITLE MODAL -->
        <h3>ポイント交換先<br class="sp">&#12288;デジタルKFC CARD </h3>
        <p class="sub-title">デジタルKFC CARDの交換となります。</p>

        <!-- CONTENT -->
        <div class="point-exchange confirm"
             :class="{ 'point-input' : !flagModalConfirm }">
          <span>交換</span>
          <strong class="point">{{ pointExchange }}</strong>
          <span>pt</span>
        </div>

        <!-- IMG ICON ARROW DOWN -->
        <img class="pc" src="../../../assets/images/icon/arrow-down.svg" alt="icon">
        <img v-if="flagModalConfirm" class="sp" src="../../../assets/images/icon/arrow-down-sp.svg" alt="icon">

        <div class="point-exchange confirm">
          <span :class="{ 'orange': !flagModalConfirm }">付与</span>
          <strong>{{ pointExchange }}</strong>
          <span :class="{ 'orange': !flagModalConfirm }">円相当</span>
        </div>

        <!-- NOTE MODAL CONFIRM -->
        <p class="note text-center">交換申請するボタンをクリックするとポイント交換が完了となります。</p>
      </div>

      <div class="btn-footer"
           slot="footer">
        <button @click="handleExchangePoint">
          <img src="../../../assets/images/icon/icon-coins.svg" alt="icon">交換申請する
        </button>
      </div>
    </Modal>

    <!-- MODAL COMPLETE EXCHANGE POINT -->
    <Modal @close-modal="handleCloseModal"
           class="modal-exchange-point modal-center"
           v-if="flagModalComplete">
      <div class="content-body"
           slot="body">
        <h3>ポイント交換先<br class="sp">&#12288;デジタルKFC CARD </h3>
        <div class="point-exchange mb-2 has-point">
          <span class="color-orange">付与ポイント</span>
          <div>
            <strong>{{ pointExchange }}<span class="color-orange sp" v-html="'円相当'"></span></strong>
            <time
              class="time-request"
              v-if="isExchangeAutoSuccess"
            >
              交換承認日：
              <span>
                {{ new Date() | formatDate('YYYY年MM月DD日') }}
              </span>
            </time>
          </div>
          <span class="pc color-orange"
                v-html="'円相当'"></span>
        </div>

        <p
          class="text-instruct"
          v-if="isExchangeAutoSuccess"
        >
          「デジタルKFC CARDを表示」ボタンからギフトをお受け取りください
        </p>

        <p
          class="text-instruct manual"
          v-if="!isExchangeAutoSuccess"
        >
          ポイント交換の申請を受け付けました。<br>承認されましたら、マイページのポイント交換履歴から、ギフトの受け取りができるようになります。
        </p>
      </div>

      <div class="btn-footer"
           slot="footer">
        <button
          class="btn-common"
          @click.prevent="openUrl(dataComplete.url)"
          v-if="isExchangeAutoSuccess"
        >
          <img src="../../../assets/images/icon/icon-coins.svg" alt="icon">デジタルKFC CARDを表示
        </button>
      </div>
    </Modal>

    <!-- MODAL NOTIFY -->
    <Modal @close-modal="turnOffNoti"
           class="modal-center modal-exchange-error"
           v-if="flagModalNoti">
      <div slot="body">
        <p v-html="messageNotification"/>

        <!-- button in modal ostiaries_error -->
        <button v-if="this.$route.meta.ostiaries_error"
                @click.prevent="handleReturn"
                v-html="(this.$route.meta.ostiaries_error === 'true') ? '番号入力画面に戻る' : '完了'"
                class="btn-into-modal"/>
      </div>
    </Modal>

    <!-- MODAL CAUTION INFO -->
      <Modal @close-modal="flagModalRedirect = false"
           class="modal-center modal-notify"
           v-if="flagModalRedirect">
          <div slot="body">
            <p>ポイント交換前に必要事項をご入力ください。</p>

            <button
              @click.prevent="handelRedirectUpdateInfo"
              v-html="'会員情報入力'"
              class="btn-into-modal"
            />
          </div>
    </Modal>

    <!-- MODAL VERIFY AUTHEN MOBILE -->
    <ModalVerifyAuthenMobile @close-modal="flagModalVerifyAuth = false"
                              v-if="flagModalVerifyAuth"/>

    <!-- MODAL NOTIFY LINE_PAY AUTHENTICATION-->
    <!-- <ModalLinePayNotification
      :turnOffLinePayNotification="turnOffLinePayNotification"
      :isLinePayValidationError="isLinePayValidationError"
      :linePayValidationErrorMessage="linePayValidationErrorMessage"/> -->
  </main>
</template>

<script>
import { ChevronLeftIcon } from 'vue-feather-icons'
import Common from '@/mixins/common.mixin'
import SNS from '@/mixins/sns.mixin'
import DigitalGift from '@/mixins/digitalGift.mixin'
import store from '@/store'
import Modal from '@/components/advertising-detail/Modal'
import ModalVerifyAuthenMobile from '@/components/ModalVerifyAuthenMobile'
import InputField from '@/components/form/InputField'
import { EXCHANGE_POINT_SERVICE } from '@/enum/exchange-service'

export default {
  name: 'Index',

  mixins: [Common, SNS, DigitalGift],

  components: {
    ModalVerifyAuthenMobile,
    InputField,
    Modal,
    ChevronLeftIcon
  },

  mounted () {
    // hide app's loading after page render
    if (Common.methods.checkWebview()) {
      Common.methods.triggerEventApp('PAGE_RENDERED', {})

      if (this.token) {
        store.dispatch('auth/userPoint')
      }
    }
  },

  computed: {
    pointExchangePayload () {
      return {
        service_id: EXCHANGE_POINT_SERVICE.kfc.id,
        point: this.pointExchangeMapping()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/exchange-service.scss';
</style>
